<template>
  <v-card rounded="xl" outlined :loading="`${currentSkillStatus}`">
    <v-card-title class="justify-space-between text-h5 font-weight-medium">
      <span>
        {{ currentSkill?.getTitle() ?? "Loading ..." }}
      </span>
      <v-btn icon @click="closeModalChild">
        <v-icon>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="5">
          <div class="pt-5">
            <div class="black--text text-body-1 font-weight-medium">
              <v-icon color="black">
                {{ mdiInformationOutline }}
              </v-icon>
              Summary
            </div>
            <div class="pb-8">
              {{ summary ?? "" }}
            </div>
          </div>
          <div class="d-flex">
            <v-row no-gutters>
              <v-col cols="4">
                <div class="black--text text-body-1 font-weight-medium">
                  <v-icon color="black">
                    {{ mdiClockOutline }}
                  </v-icon>
                  Time
                </div>
                <div class="pb-8">
                  {{ displayTime ?? "Loading ..." }}
                </div>
              </v-col>
              <v-col cols="4">
                <div class="black--text text-body-1 font-weight-medium">
                  <v-icon color="black">
                    {{ mdiSignal }}
                  </v-icon>
                  Level
                </div>
                <div class="pb-8">
                  {{ currrentSkillLevel ?? "Loading ..." }}
                </div>
              </v-col>
              <v-col cols="4">
                <div class="black--text text-body-1 font-weight-medium">
                  <v-icon color="black">
                    {{ languageIcon }}
                  </v-icon>
                  Language
                </div>
                <div class="pb-8">
                  {{ showLanguage }}
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="7" class="fill-height">
          <div class="pt-5">
            <div class="black--text text-body-1 font-weight-medium">
              Description
            </div>
            <div
              class="contents"
              v-html="skillDescription ?? 'Loading ...'"
            ></div>
          </div>
          <!-- <div class="pt-5">
            <div class="black--text text-body-1 font-weight-medium">
              About the Expert
            </div>
            <div
              class="contents"
              v-html="expertDetailsHtml ?? 'Loading ...'"
            ></div>
          </div> -->
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <div class="black--text text-body-1 font-weight-medium">
        About the Expert
      </div>
      <v-row class="pt-5">
        <v-col cols="3">
          <v-img class="expert-img" :src="expertImageUrl"> </v-img>
        </v-col>
        <v-col cols="8">
          <div
            class="contents"
            v-html="expertDetailsHtml ?? 'Loading ...'"
          ></div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { mdiClockOutline, mdiSignal, mdiInformationOutline } from "@mdi/js";
import {
  mdiWeb,
  mdiLanguagePython,
  mdiLanguageJava,
  mdiLanguageKotlin,
  mdiLanguageCpp,
  mdiClose,
} from "@mdi/js";
export default {
  props: {
    skill: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiInformationOutline,
      mdiClockOutline,
      mdiSignal,
      mdiLanguageKotlin,
      mdiLanguageCpp,
      mdiLanguageJava,
      mdiLanguagePython,
      mdiWeb,
      mdiClose,
    };
  },
  computed: {
    ...mapState("recruiter", [
      "currentSkill",
      "currentSkillStatus",
      "currentSkillStatus",
    ]),
    ...mapGetters("recruiter", ["skillLevelKeys", "prettySkillLevels"]),
    skillDescription() {
      return (
        this.currentSkill
          ?.getDataView()
          ?.getSkillView()
          ?.getDescriptionHtml() || "No Description"
      );
    },
    expertDetailsHtml() {
      return (
        this.currentSkill
          ?.getDataView()
          ?.getSkillView()
          ?.getExpertDetailsHtml() || "No Expert"
      );
    },

    displayTime() {
      const timeInS = this.currentSkill?.getMetaData()?.getTimeLimit();

      return moment.duration(timeInS / 60, "minutes").humanize();
    },

    // allowedLanguageID() {
    //   return currentSkill.getDataView().getSkillView().getLanguageRestriction().getAllowLanguageList()
    // },

    languageIcon() {
      let allowedLanguages =
        this.currentSkill
          ?.getDataView()
          ?.getSkillView()
          ?.getLanguageRestriction()
          ?.getAllowLanguageList() || [];
      const languageMap = {
        1: "CPP",
        2: "Java",
        3: "Python",
        4: "Kotlin",
      };
      if (allowedLanguages.length) {
        if (allowedLanguages[0] === 1) {
          return this.mdiLanguageCpp;
        } else if (allowedLanguages[0] === 2) {
          return this.mdiLanguageJava;
        } else if (allowedLanguages[0] === 3) {
          return this.mdiLanguagePython;
        } else {
          return this.mdiLanguageKotlin;
        }
      } else {
        return this.mdiWeb;
      }
    },

    showLanguage() {
      let allowedLanguages =
        this.currentSkill
          ?.getDataView()
          ?.getSkillView()
          ?.getLanguageRestriction()
          ?.getAllowLanguageList() || [];
      if (allowedLanguages.length) {
        if (allowedLanguages[0] === 1) {
          return "CPP";
        } else if (allowedLanguages[0] === 2) {
          return "Java";
        } else if (allowedLanguages[0] === 3) {
          return "Python";
        } else {
          return "Kotlin";
        }
      } else {
        return "CPP, Java, Python, Kotlin";
      }
    },

    currrentSkillLevel() {
      const num = this.currentSkill
        ?.getMetaData()
        ?.getSkillMeta()
        ?.getSkillLevel();
      return this.prettySkillLevels[this.skillLevelKeys[num]];
    },

    summary() {
      return this.skill.metaData?.skillMeta?.summary || "No summary";
    },

    expertImageUrl() {
      return (
        this.currentSkill?.getDataView()?.getSkillView()?.getExpertImageUrl() ||
        "https://media.istockphoto.com/photos/portrait-of-young-businessman-standing-in-his-office-with-arms-picture-id1193290387?s=612x612"
      );
    },
  },
  methods: {
    ...mapActions("recruiter", ["fetchSkill"]),
    ...mapMutations("recruiter", ["fetchSkillStatus", "setCurrentSkill"]),
    closeModalChild() {
      this.$emit("closemodal");
    },
  },
  created() {
    this.fetchSkill({
      id: this.skill.id,
    });
    console.log("SUKIRU", this.currentSkill?.getDataView().getSkillView());
  },
  destroyed() {
    console.log("destroyed");
    this.fetchSkillStatus(null);
    this.setCurrentSkill(null);
  },
};
</script>
<style scoped>
.expert-img {
  border-radius: 50%;
  height: 200px;
  width: 200px;
}
</style>
